import { graphql, HeadFC, PageProps } from "gatsby"
import { websiteDto } from "../../../utils/website-dto/crystal-peak"
import { WebsitePayload } from "../../../utils/website-dto/website-payload"
import HomeHelper from "../helpers/home-helper"
import { CrystalPeakHomePageType } from "../helpers/home-helper.types"
import { mapWebsiteResponseToDTOArgs } from "../../../utils"

const IndexPage = ({
    data: {
        api: { website, business, user },
    },
    ...rest
}: PageProps<{ api: WebsitePayload }>) => {
    const requiredData = websiteDto(
        mapWebsiteResponseToDTOArgs(user, business, website)
    ) as unknown as CrystalPeakHomePageType

    return <HomeHelper {...requiredData} cNavigate={rest.navigate} cLocation={rest.location} />
}
export const Head: HeadFC = () => <title>Home Page</title>

export default IndexPage

export const query = graphql`
    query ($businessId: String!, $userId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
            user(where: { id: $userId }) {
                ...UserInfo
            }
        }
    }
`
