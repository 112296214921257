import { FC } from "react"
import {
    About,
    CompletedProjects,
    ContactUs,
    Cta,
    Faqs,
    Footer,
    Header,
    Hero,
    HighlightedServices,
    OurProcess,
    Testimonials,
    TradeCategories,
} from "../../../components/crystal-peak/sections"
import type { CrystalPeakHomePageType } from "./home-helper.types"
import Guarantees from "../../../components/crystal-peak/sections/guarantees"

export const HomeHelper: FC<CrystalPeakHomePageType> = ({
    headerProps,
    heroProps,
    tradeCategoriesProps,
    highlightedServicesProps,
    ourProcessProps,
    testimonialsProps,
    completedProjectsProps,
    faqsProps,
    aboutUsProps,
    contactUsProps,
    ctaHomeNavigateHeroProps,
    ctaHomeSendRequestQuoteProps,
    guaranteesProps,
    footerProps,
    cNavigate,
    cLocation,
}) => {
    return (
        <>
            <header>
                <Header {...headerProps} navigate={cNavigate} path={cLocation?.pathname} />
            </header>
            <main>
                <Hero {...heroProps} />
                <TradeCategories {...tradeCategoriesProps} />
                <HighlightedServices {...highlightedServicesProps} />
                <OurProcess {...ourProcessProps} />
                <Testimonials {...testimonialsProps} />
                <Guarantees {...guaranteesProps} />
                <Cta {...ctaHomeNavigateHeroProps} navigate={cNavigate} />
                <CompletedProjects {...completedProjectsProps} />
                <Faqs {...faqsProps} />
                <About {...aboutUsProps} />
                <ContactUs {...contactUsProps} />
                <Cta {...ctaHomeSendRequestQuoteProps} variant={"about"} />
            </main>
            <footer>
                <Footer {...footerProps} />
            </footer>
        </>
    )
}

export default HomeHelper
