import { BaseCard, Typography } from "@trades-org/waas-component-library"
import { StaticImage } from "gatsby-plugin-image"
import { FC } from "react"
import Section from "../../common/section"
import SectionHeading from "../../common/section-heading"

interface GuaranteeType {
    title: string
    description?: string
}

export interface GuaranteesProps {
    title: string
    slogan?: string
    description?: string
    guarantees?: GuaranteeType[]
}

const Guarantees: FC<GuaranteesProps> = ({ title, slogan, description, guarantees }) => {
    if (!guarantees || !guarantees.length) return null

    return (
        <Section sectionClassNames="flex flex-wrap bg-[#F9F9FC] md:pt-20 md:pb-10">
            <div id="guarantees" className="w-full md:w-1/3">
                <SectionHeading title={title} slogan={slogan} wrapperClassNames="md:pb-10" />
                <Typography textType="p" className="text-[#4A4A4A] text-sm">
                    {description as string}
                </Typography>
            </div>
            <div className="w-full md:w-2/3 pt-10 md:py-0 grid grid-cols-1 md:grid-cols-2 gap-16">
                {guarantees.map((guarantee, index) => (
                    <BaseCard
                        key={`guarantee-${index}`}
                        wrapperClassNames={"flex flex-col justify-between"}
                        textGroupProps={{
                            titleClassNames: "text-sm font-semibold text-left py-4",
                            title: guarantee.title,
                            description: guarantee.description,
                            descriptionClassNames: "text-xs text-left text-[#4A4A4A]",
                            titleTextType: "h3",
                        }}
                        baseButtonProps={undefined}
                        icon={
                            <div className="w-[40px] h-[40px] flex items-center justify-center bg-[#D6CDF4] grow-0 self-start rounded-md">
                                <StaticImage
                                    width={18}
                                    height={13}
                                    src={"../../../../../images/crystal-peak/trade-categories/mountain-icon.svg"}
                                    alt={"Guarantee icon"}
                                />
                            </div>
                        }
                    />
                ))}
            </div>
        </Section>
    )
}

export default Guarantees
